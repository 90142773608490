import React, { useState } from "react"
import { GetDineinorderHook } from "../Componentslogic/ordersLogic/GetsoneorderHook"
import { useParams, Link } from "react-router-dom"
import { Card, Col, Form, Row, Modal, Button } from "react-bootstrap"
import arrow from "../images/left-arrow.png"
import { motion as m } from "framer-motion"
import useFetchDocument from "../Componentslogic/Usefetchdocument"

export const Oneorderdetails = () => {
  const { id } = useParams()

  const { document: dineinorder } = useFetchDocument("Orders", id)

  const [, handlePaiddetails, handleDelete, , , paid, disable] =
    GetDineinorderHook(id, dineinorder)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const user = JSON.parse(localStorage.getItem("user"))
  const getAdjustedLink = () => {
    if (user.email === process.env.REACT_APP_ADMIN_EMAIL) {
      return "/adminallorders"
    } else {
      return "/Staffpage/DineIn"
    }
  }

  return (
    <m.div
      className="p-4 "
      initial={{ opacity: 0, x: 15 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete Order of{" "}
          <strong>{dineinorder?.userName}</strong> on table : {""}
          <strong>{dineinorder?.tablenumber}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={(e) => handleDelete(e, dineinorder.id)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* // 1-Show order details */}
      <Link to={getAdjustedLink()}>
        <img
          style={{ height: "25px", width: "25px" }}
          src={arrow}
          alt="Back-arrow"
        ></img>
      </Link>
      <div>
        <m.h2
          className="TNumber  d-flex justify-content-end"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {dineinorder?.tablenumber || "No Table Number"}
        </m.h2>
        <m.h2
          className="Review-total mb-5"
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Order details
        </m.h2>
      </div>

      <div className="Product-Card justify-content-center ">
        {dineinorder ? (
          dineinorder.cartItems.map((i, index) => {
            return (
              <Card
                text="dark"
                style={{ width: "18rem" }}
                className="Review-Card-F mx-3"
                key={index}
              >
                <Card.Header
                  style={{
                    backgroundColor: i.updatedItem ? "#2f5a76" : "#f8f9fa",
                    color: i.updatedItem ? "white" : "black",
                  }}
                >
                  {i.updatedItem
                    ? "Updated"
                    : null}
                </Card.Header>

                <Card.Body>
                  <Card.Title>Title: {i.name} </Card.Title>
                  <Card.Text>quantity: {i.CartTotalQuantity}</Card.Text>
                  <Card.Text>
                    price: {i.Price} x {i.CartTotalQuantity}
                  </Card.Text>
                  {i.updatedNotes ? (
                    <Card.Text>
                      <b>Note:</b> {i.updatedNotes}
                    </Card.Text>
                  ) : null}
                </Card.Body>
              </Card>
            )
          })
        ) : (
          <h2>no data</h2>
        )}
      </div>
      {dineinorder?.Notes && (
        <div className="Notes-Card mb-3">
          <p>
            <strong>Notes:</strong>
          </p>
          <p>{dineinorder.Notes}</p>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <button className=" btns-red" onClick={handleShow}>
          Delete Order
        </button>
      </div>
      <div className="horizontal-divider"></div>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={dineinorder?.userName}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Email
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={dineinorder?.userEmail}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Total Order price:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              defaultValue={dineinorder?.orderAmount}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Ordered At:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              plaintext
              readOnly
              value={`${dineinorder?.orderDate || "Unknown"} at ${
                dineinorder?.orderTime || "Unknown"
              }`}
            />
          </Col>
        </Form.Group>
        {/* //updated */}
        {dineinorder?.SeenAtDate ? (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Seen At:
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={`${dineinorder?.SeenAtDate || ""} at ${
                  dineinorder?.SeenAtTime || ""
                }`}
              />
            </Col>
          </Form.Group>
        ) : null}
        {/* //updated */}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Order payment status:
          </Form.Label>
          <Col sm="3">
            <Form.Select
              onChange={(e) => handlePaiddetails(e, dineinorder.id)}
              disabled={disable}
            >
              <option>{paid}</option>
              <option>Paid</option>
            </Form.Select>
          </Col>
        </Form.Group>

        {dineinorder?.paidAT && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Paid At
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={new Date(
                  dineinorder.paidAT.seconds * 1000
                ).toLocaleString()}
              />
            </Col>
          </Form.Group>
        )}

        {dineinorder?.CheckRequestedAt && (
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">
              Check Requested At
            </Form.Label>
            <Col sm="10">
              <Form.Control
                plaintext
                readOnly
                value={new Date(
                  dineinorder.CheckRequestedAt.seconds * 1000
                ).toLocaleString()}
              />
            </Col>
          </Form.Group>
        )}
      </Form>
    </m.div>
  )
}
